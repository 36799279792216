<template>
    <div style="padding: 10px;height: 100%;" class="d-flex flex-column">
        <v-card elevation="1" class="flex-grow-1" style="user-select: none">
            <v-stepper v-model="outIndex" elevation="0" vertical>
                <template v-for="(index,i) in infos" v-model="infos">
                    <v-stepper-step editable :key="i" :step="i+1" :complete="i+1<outIndex" edit-icon="mdi-check"
                                    @click="innerIndex=1" :rules="[()=>index.error===undefined]">
                        {{index.title}}
                        <small v-show="index.error!==undefined">您有未填项</small>
                    </v-stepper-step>
                    <v-stepper-content :step="i+1">
                        <v-stepper v-model="innerIndex" vertical style="margin: 0">
                            <div v-for="(children,j) in index.children" :key="j">
                                <v-stepper-step editable :step="j+1" :complete="j+1<innerIndex"
                                                :rules="[()=>children.error===undefined]">
                                    {{children.title}}
                                </v-stepper-step>
                                <v-stepper-content :step="j+1">
                                    <ul class="d-flex flex-wrap" style="margin: 0;padding: 0">
                                        <li v-for="(item,k) in children.items" :key="k">
                                            <div class="d-flex justify-space-between align-center"
                                                 style="margin-bottom: 8px">
                                                <div style="margin-top: 2px" v-text="item.title"></div>
                                                <el-select v-model="item.rank" placeholder="等级"
                                                           style="max-width: 90px">
                                                    <el-option v-for="rank in ranks" :key="rank.value"
                                                               :label="rank.label" :value="rank.value"></el-option>
                                                </el-select>
                                            </div>
                                            <v-text-field outlined dense label="描述信息" style="margin-top: 20px"
                                                          :value="item.des">
                                            </v-text-field>
                                        </li>
                                    </ul>
                                    <div>
                                        <v-btn color="primary" :disabled='innerIndex===1&&outIndex===1'
                                               @click="innerStepPre(i,j)"
                                               style="margin-right: 20px">上一个
                                        </v-btn>
                                        <v-btn color="primary" @click="innerStepNext(i,j+1)">下一个</v-btn>
                                    </div>
                                </v-stepper-content>
                            </div>
                        </v-stepper>
                    </v-stepper-content>
                </template>
            </v-stepper>
            <v-btn color="primary" style="margin-left: 30px" @click="submit">提交</v-btn>
        </v-card>
    </div>
</template>


<script>
    import * as safeInfo from '../../network/Details/Super_admin/houseSafetyInfo.js'

    export default {
        name: "HouseSafetyInfo",
        data: () => ({
            buildingId: 0,
            infos: [
                {
                    children: [{}],
                    title: ""
                }
            ],
            outIndex: 1,
            innerIndex: 1,
            ranks: [
                {
                    label: '',
                    value: ''
                }
            ],
        }),
        created() {
            this.buildingId = this.$route.query.buildingId
            safeInfo.getRanks().then(res => {
                if (res.status === 200) {
                    let data = res.data
                    let ranks = []
                    for (let i = 0; i < data.length; i++) {
                        ranks.push({
                            label: data[i].infoRankName,
                            value: data[i].infoRankId
                        })
                    }
                    this.ranks = ranks
                }
            })
            safeInfo.getInfo({
                buildingId: this.buildingId
            }).then(res => {
                if (res.status === 200) {
                    this.infos = res.data.infos
                }
            }).catch(err => {
                console.log(err)
            })
        },
        methods: {
            getRankId(rank) {
                if (typeof rank === "number")
                    return rank
                for (let i = 0; i < this.ranks.length; i++) {
                    if (rank === this.ranks[i].label) {
                        return this.ranks[i].value
                    }
                }
            },
            submit() {
                let pass = true;
                for (let i = 0; i < this.infos.length; i++) {
                    let children = this.infos[i].children
                    for (let j = 0; j < children.length; j++) {
                        //验证是否全部填完，如果 pass 为 false 就保留状态，
                        // 否则可能会出现状态被后续篡改的情况
                        if (pass === true) {
                            pass = this.verifyStep(i, j)
                        } else {
                            this.verifyStep(i, j)
                        }
                    }
                }
                if (pass !== true) {
                    this.$message({
                        message: "您有未填项，请重新检查确认",
                        type: "error"
                    })
                    return
                }
                let arr = [];
                for (let i = 0; i < this.infos.length; i++) {
                    let children = this.infos[i].children
                    for (let j = 0; j < children.length; j++) {
                        let items = children[j].items;
                        for (let k = 0; k < items.length; k++) {
                            let temp = {};
                            temp['securityInfoId'] = items[k].infoId;
                            temp['securityIndexId'] = items[k].indexId;
                            temp['buildingId'] = this.buildingId;
                            temp['securityInfoDes'] = items[k].des;
                            temp['securityInfoRank'] = this.getRankId(items[k].rank);
                            temp['indexParentId'] = children[j].indexId;
                            arr.push(temp);
                        }
                    }
                }
                safeInfo.updateInfos(arr).then(res => {
                    if (res.status === 200) {
                        this.$message({
                            message: "提交成功",
                            type: "success"
                        })
                    } else {
                        this.$message({
                            message: "提交失败",
                            type: "error"
                        })
                    }
                }).catch(err => {
                    this.$message({
                        message: "提交失败",
                        type: "error"
                    })
                    console.log(err)
                })
            },
            verifyStep(i, j) {
                //验证是否有子项未填写完
                let items = this.infos[i].children[j].items
                for (let k = 0; k < items.length; k++) {
                    if (items[k].rank === '') {
                        //有未填项，验证失败，设置 error 状态
                        this.$set(this.infos[i].children[j], 'error', true);
                        this.$set(this.infos[i], 'error', true);
                        return false;
                    }
                }
                //验证通过，取消 error 状态
                this.$set(this.infos[i].children[j], 'error', undefined);
                this.$set(this.infos[i], 'error', undefined);
                return true;
            },
            innerStepPre(i, j) {
                //内部步骤条 “上一步” 按钮点击事件
                this.verifyStep(i, j)
                if (j < 1) {
                    if (this.outIndex > 1) {
                        let length = this.infos[i - 1].children.length
                        this.outIndex -= 1;
                        this.innerIndex = length;
                    } else
                        this.innerIndex = 1;
                } else {
                    this.innerIndex = j;
                }
            },
            innerStepNext(i, j) {
                //内部步骤条 “下一步” 按钮点击事件
                this.verifyStep(i, j - 1)
                let length = this.infos[i].children.length
                if ((j + 1) > length) {
                    this.innerIndex = 1;
                    this.outIndex += 1;
                } else {
                    this.innerIndex = j + 1;
                }
            },
        },
    }
</script>

<style scoped>
    ul > li {
        max-width: 300px;
        list-style: none;
        padding: 0 7px;
    }
</style>
