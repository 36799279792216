import {request} from "../../request";

export function getInfo(data) {
    return request({
        url: 'safety/infos',
        method: 'get',
        params: data
    })
}

export function updateInfos(data) {
    return request({
        url: 'safety/updateInfos',
        method: 'post',
        data: data,
    })
}
export function getRanks() {
    return request({
        url: 'safety/ranks',
        method: 'get',
        data: {},
    })
}